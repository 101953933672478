















// import { contentRouter } from '@/config/setting'
import { Component, Vue, Prop, Mixins, Watch } from 'vue-property-decorator'
import * as utils from '@/modules/common/components/info-form/components/filters/utils'

  @Component({
    components: {
      
    }
  })
export default class ClassList extends Vue {
  imgParam:any = { width: 340, height: 192, orient: 1 }

  @Prop() item: any
  // get shopShowData() {
  //   return this.$store.getters.shopShowData
  // }
  imgSrc (data:any, param:any) {
    return utils.createImgsrc(data, param)
  }
}
