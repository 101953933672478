




















































import { Component, Vue, Prop } from 'vue-property-decorator'
import { Input, Popover } from 'element-ui'
import dsIcon from '@/modules/common/components/ds-icon.vue';
import * as User from '@/utils/user'
import ContentLayout from '@/modules/course/components/content-layout.vue'
import ueditorDisplay from '@/modules/common/components/ueditor-display/index.vue';
import gAudio from '@/modules/common/components/g-audio.vue';
import gHlsVideo from '@/modules/common/components/g-hls-video.vue';
import setTopAudio from '@/modules/common/components/set-top-audio.vue';
import { utils } from '@/utils/utils';
import * as model from '@/modules/content/models/index'
import { getContentTypeSn } from '@/config/typeConfig'
import SpeedBtn from '@/modules/common/components/speed-btn.vue'
import { getColumnCountents } from '@/modules/column/api/index'
import catalog from '@/modules/column/pages/components/catalog.vue'
import { getColumnDetail } from '@/modules/column/api'
import ClassCourseInfo from '@/modules/course/pages/course-class/components/class-course-info.vue'

@Component({
  components: {
    SpeedBtn,
    ueditorDisplay,
    gAudio,
    gHlsVideo,
    setTopAudio,
    ContentLayout,
    catalog,
    ClassCourseInfo
  }
})
export default class ContentAfterBuy extends Vue {
  columnObj:any = []
  courseDetail:any = {}
  @Prop({ default: {} }) contentInfo?: any
  isAudioSetTop: boolean = false
  hasAudioPlay: boolean = false
  mediaSpeed: number = 1
  userInfo: object = {
    uid: '',
    nick_name: '',
    avatar: ''
  }
  getImgSrc(indexpic: any) {
    return utils.createImgSrc(this.contentInfo.indexpic, { width: 1080 })
  }
  get getInfo() {
    const info = this.contentInfo
    const type = info.type
    const commentCount = utils.numberShowRules1(info.comment_count)
    let infoTips = ''
    switch (type) {
      case 'article':
      case 'audio':
      case 'video':
        infoTips = `${info.brief_temp}`
        break
      case 'course':
        // infoTips = `课时${hourCount} | 订阅${subscribeCount} | ${info.update_time} | ${this.getExpireTime()}`
        break
      default:
        infoTips = ''
        break
    }
    return infoTips
  }
  get playData() {
    return {
      fileId: this.contentInfo.file_id,
      token: this.contentInfo.token
    }
  }
  created() {
  }

  mounted() {
    window.onscroll = () => {
      if (this.$refs.audioBox) {
        let audioBox: any = this.$refs.audioBox
        this.isAudioSetTop = audioBox.getBoundingClientRect().bottom < 50
      }
    }
    this.getColumnCountents()
    this.getCloumnInfo()
  }
  beforeDestroy() {
    window.onscroll = null
  }
  toPlayAudio() {
    this.hasAudioPlay = true
    this.$nextTick(() => {
      let setTopAudio: any = this.$refs.setTopAudio
      setTopAudio.play()
    })
  }
  toPage (item:any) {
    if (this.courseDetail.is_subscribe) {
      // this.$router.push({ path: item.router,query: {class_content: this.contentObj} })
      this.$router.push({ name: 'column',params: {type: item.type,id: item.content_id,column_id: this.contentInfo.column_id} })
    } else {
      this.$message('订阅后才能看')
    }
  }
  speedChangedHandler($event: number) {
    if (this.contentInfo.type == 'audio' && this.$refs.setTopAudio) {
      ;(this.$refs.setTopAudio as gAudio).changeSpeed($event)
    } else if (this.contentInfo.type == 'video' && this.$refs.videoArea) {
      ;(this.$refs.videoArea as gHlsVideo).changeSpeed($event)
    }
    this.mediaSpeed = $event
  }
  getColumnCountents() {
    let id = this.$route.params.column_id
    let params:any = { 
      column_id: id
    }  
    getColumnCountents(params).then(res => {
      this.columnObj = res.data
    })
    
  }
  getCloumnInfo() {
    let id = this.$route.params.column_id
    getColumnDetail(id).then(res => {
      this.courseDetail = res.data
    })

  }
}
